<template>
  <div id="10_risk_acceptance_container" :class="cardSizeClass">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- WARNINGS-DIV -->
                  <div v-if="currentRiskStep == 0" class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-1xl card-sub-title">
                          Constancia electrónica de riesgos
                        </p>
                        <small>
                          Es importante que conozcas algunos riesgos que existen al invertir con nosotros.<br>
                          Completa la constancia electrónica que se presenta a continuación para conocer sobre estos riesgos, 
                          si no estás de acuerdo no podremos continuar con el proceso de verificación de tu cuenta y no podrás invertir.
                        </small>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-5">
                      <div class="vx-row">
                        <div :class="colClassFull">
                          <label class="bold">Advertencias</label>
                        </div>
                      </div>
                      <div class="vx-row mt-4">
                        <div :class="colClass" v-for="warning in warningsData" :key="warning.index">
                          <div class="flex">
                              <vs-avatar color="primary" icon-pack="feather" size="10px" />
                              <div :class="colClassFull">
                                  <label class="bold mt-5">{{warning.title}}</label><br>
                                  <small>{{warning.subtitle}}</small>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button class="w-full mt-4" @click="showNextStep"
                            >Estoy de acuerdo</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END WARNINGS-DIV -->
                  <!-- RISKS-DIV -->
                  <div v-else-if="currentRiskStep == 1" class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">
                          Riesgos
                        </p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-5">
                      <div class="vx-row mt-4">
                        <div :class="colClassFull">
                          <vx-card class="mb-4 border-primary" v-for="risk in risksData" :key="risk.index">
                              <vs-row vs-w="12" vs-justify="center" vs-align="center" vs-type="flex">
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="7">
                                      <label class="bold">{{risk.title}}</label><br>
                                      <small>{{risk.subtitle}}</small>
                                  </vs-col>
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="5">
                                      <vs-checkbox class="ml-5 w-full" color="dark" v-model="risksCheckBoxes" :vs-value="risk.value">Estoy de acuerdo</vs-checkbox>
                                  </vs-col>
                              </vs-row>
                          </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button :disabled="risksCheckBoxes.length != 4" class="w-full mt-4" @click="showNextStep"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END RISKS-DIV -->
                  <!-- INVESTMENTS-DIV -->
                  <div v-else class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">
                          Acerca de la inversión
                        </p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-5">
                      <div class="vx-row mt-4">
                        <div :class="colClassFull">
                          <vx-card class="mb-4 border-primary" v-for="investment in investmentsData" :key="investment.index">
                              <vs-row vs-w="12" vs-justify="center" vs-align="center" vs-type="flex">
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="7">
                                      <label class="bold">{{investment.title}}</label><br>
                                      <small>{{investment.subtitle}}</small>
                                  </vs-col>
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="5">
                                      <vs-checkbox class="ml-5 w-full" color="dark" v-model="investmentsCheckBoxes" :vs-value="investment.value">Estoy de acuerdo</vs-checkbox>
                                  </vs-col>
                              </vs-row>
                          </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="10_risk_acceptance_confirm_btn" :disabled="investmentsCheckBoxes.length != 2" class="w-full mt-4" @click="showNextStep"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END INVESTMENTS-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
  mixins: [inputHelper],
  props: ["onboardingStepData"],
  data() {
    return {
      colClassFull: "vx-col w-full",
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      currentRiskStep: 0,
      contractRiskType:15,
      currentLocation:null,
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      },
      warningsData:[
        { title: 'Analiza la información', subtitle: 'Es importante que antes de realizar cualquier inversión, analices la información presentada por cada proyecto, tales como la calificación, tasa, plazo, garantía(s), giro, entre otros.' },
        // { title: 'Retiro de fondos', subtitle: 'Una vez que inviertas en algún proyecto, no podrás retirar los fondos a menos que se cancele o no logre reunir los fondos requeridos.' },
        { title: 'Retiro anticipado', subtitle: 'Actualmente no contamos con un mercado secundario, por lo que no existirán las condiciones para que, a través de RedGirasol, lleves a cabo la venta de los derechos que documenten las operaciones. Es decir, no podrás retirarte anticipadamente de los proyectos en los que participes.' },
        { title: 'No pago del solicitante', subtitle: 'En caso de que el Solicitante no pague su crédito, se realizarán labores de cobranza extrajudicial y judicial para la recuperación del monto del crédito y/o de las respectivas garantías. Sin embargo, a pesar de realizar dichos esfuerzos, existe la posibilidad de que el monto no sea recuperado y por consecuencia, se pierda parcial o totalmente la inversión realizada en el proyecto.' },
        { title: 'Información del solicitante', subtitle: 'Es posible que la información del Solicitante, con la cual realizamos el análisis de riesgo crediticio, no haya sido dictaminada por un auditor externo independiente. Esto significa que los riesgos publicados de los proyectos podrían ser distintos a los reales.' },
        { title: 'Información inicial', subtitle: 'La información a la cual tendrás acceso tanto de forma inicial, como subsecuente a la etapa de fondeo, podrá ser limitada en comparación a lo observado en el mercado de valores.' },
        { title: 'Garantía de recursos', subtitle: 'Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse  o garantizar los recursos de los Clientes que sean utilizados en las Operaciones que celebren con las ITF  o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas por las  ITF o por algún Cliente frente a otro, en virtud de las Operaciones que celebren.' },
        { title: 'Rendimientos', subtitle: 'Los rendimientos sobre las inversiones que realices no son garantizados por nosotros ni podemos asegurar que vayan a ser exitosas ya que los retornos se derivan exclusivamente del comportamiento de pago de los Solicitantes a los cuales les prestes recursos.' },
        { title: 'Aceptación de riesgos', subtitle: 'Antes de poder invertir en los proyectos de la Red, será necesario que comprendas y aceptes los riesgos, formas y términos generales de la plataforma a través del cuestionario que se te presentará a continuación.' }
      ],
      risksCheckBoxes: [],
      risksData:[
        { title: 'Pérdida de dinero', subtitle: 'Las inversiones en RedGirasol no están garantizadas, por lo que podrás perder parte o todo tu dinero.', value:'1' },
        { title: 'Disposición de fondos', subtitle: 'No podrás disponer de tus fondos mientras tu dinero se encuentre en inversión efectiva.', value:'2' },
        { title: 'Asesorías', subtitle: 'RedGirasol no proporciona asesoría específica sobre las inversiones.', value:'3' },
        { title: 'Generación de rendimientos', subtitle: 'Acepto que los montos que no permanezcan en inversión efectiva no generarán rendimientos.', value:'4' }
      ],
      investmentsCheckBoxes: [],
      investmentsData:[
        { title: 'Comprendo los riesgos', subtitle: 'He leído este formulario y comprendo los riesgos de llevar a cabo estas inversiones.', value:'1' },
        { title: 'Revisión de la información', subtitle: 'Comprendo que además de los riesgos generales de la inversión, debo leer y entender la información divulgada por los Solicitantes de financiamiento.', value:'2' },
      ],
    };
  },
  async mounted(){
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    cardSizeClass(){
      if (this.currentRiskStep == 0) {
        return "vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0";
      } else {
        return "vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0";
      }
    }
  },
  methods: {
    async showNextStep(){
        if (this.currentRiskStep == 2) {
            await this.saveDataForm();
        } else {
            this.currentRiskStep += 1;
        }
    },
    async saveDataForm() {
      this.showLoading(true, "Guardando información...");
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          contractType: this.contractRiskType, 
          geolocation: geolocation
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStep",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
    onErrorLocation(err){
      // console.log(err);
    },
  },
};
</script>
<style scoped>
.vx-card.border-primary{
    border-style: solid;
    border-width: 1px;
}
</style>
