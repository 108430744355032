<template>
  <div id="13_automatic_investment_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Consentimientos</p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClassFull">
                            <vx-card class="border-primary">
                                <h5>Inversión Automática (Contratación)</h5>
                                    <p class="mt-5">
                                        Al dar clic en Autorizar confirmarás que Red Girasol podrá invertir automáticamente tus pagos en el momento en que actives la inversión automática <strong>(que se encuentra desactivado por defecto)</strong> en el apartado de “Planes de inversión”. Podrás habilitar y deshabilitar la inversión automática en el momento en el que requieras.
                                        <br><br>Por este medio, <strong><u>{{userName}}</u></strong> en mi calidad de Comitente del Contrato de Comisión Mercantil, autorizo a Red Girasol para que pueda invertir automáticamente los recursos que mantengo en el Sitio en nuevos Proyectos, bajo los esquemas de riesgo y condiciones que he señalado en el Sitio.
                                    </p>
                            </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 sm:w-full w-full"></div>
                        <div class="vx-col md:w-2/3 sm:w-full w-full">
                          <vs-button id="13_automatic_investment_confirm_btn" class="w-full mt-5" @click="saveDataForm"
                            >Autorizar y continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
export default {
  mixins: [inputHelper],
  components: {LatestInvestorMerchantContract},
  props: ["onboardingStepData"],
  data() {
    return {
      errorMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      colClassFull: "vx-col w-full",
      currentLocation:null,
      autoInvestmentContractType: 18,
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      },
    };
  },
  async mounted(){
    this.user.complete_name = this.onboardingStepData.full_name;
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    userName: function () {
      return (this.user === null) ? "-" : this.user.complete_name;
    },
  },
  methods: {
    async saveDataForm() {
      this.showLoading(true, "Guardando información...");
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          contractType: this.autoInvestmentContractType, 
          geolocation: geolocation
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStep",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
    onErrorLocation(err){
      // console.log(err);
    },
  },
};
</script>
<style scoped>
.vx-card.border-primary{
    border-style: solid;
    border-width: 1px;
}
</style>
